import React from 'react';
import './about.scss';


interface Props {}

export function AboutDivision(props: Props) {

  return (
    <div className="col-xs-12 post about">
        <div className="box">
          <h2>{'About Abav'}</h2>
          <p className="description">
            {'Your Authorized Alarm.com Dealer'}
          </p>
          <p className="description">
            {'Ottawa | ON | K1B 5N3'}
          </p>
          <p className="description">
            {'613-738-7707'}
          </p>
        </div>
      </div>
  );
}