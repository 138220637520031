import React from 'react';
import Strapi from 'strapi-sdk-javascript/build/main';
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home } from './screens/home/home';

const strapi = new Strapi('http://207.154.240.216:1338');

class App extends React.Component<{}, { posts: any[], pages: any[] }> {
  constructor(props: any) {
    super(props);
    this.state = {
      posts: [],
      pages: []
    };
  }

  async componentDidMount() {
    try {
      const pages = await strapi.getEntries('pages')
      console.log('pages: ', pages);
      this.setState({ pages });
    }
    catch (err) {
    }
  }

  render() {
    return (
      <Router>
        <div>
          <div className="container">
            <div className="row">
              <img src="http://207.154.240.216:1338/uploads/1ed9b760d38545b7af458abeb10db50c.jpeg" alt="" />
              <nav>
                <ul>
                  {this.state.pages.map((page, index) => {
                    return (
                      <li key={index}>
                        <Link to={'/' + page.name}>{page.name}</Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <div className="container">
            <Switch>
              {this.state.pages.map((page, index) => {
                return (
                  <Route path={'/' + page.name} key={index}>
                    <Home page={page.name} />
                  </Route>
                )
              })}
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default App;
