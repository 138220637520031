import React from 'react';
import './contact-us.scss';


interface Props {}

export function ContactUsDivision(props: Props) {

  return (
    <div className="col-xs-12 post request-consultation">
      <div className="box">
        <h2>{'REQUEST A CONSULTATION'}</h2>
        <p className="description">
          {'Protect your home with security that goes the extra mile to keep you aware and in control — from anywhere!'}
        </p>
        <div className="contact-btn">
          Contact us
        </div>
      </div>
    </div>
  );
}