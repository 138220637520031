import React, { useState, useEffect } from 'react';
import Strapi from 'strapi-sdk-javascript/build/main';

import './home.scss';
import { ContactUsDivision } from '../../components/contactus/contact-us';
import { AboutDivision } from '../../components/about/about';

const strapi = new Strapi('http://207.154.240.216:1338');

interface HomeProps {
  page: string;
}

export function Home(props: HomeProps) {
  const [posts, setPosts] = useState<any[]>([]);

  useEffect(() => {
    getPosts();
  }, [props.page]);

  async function getPosts() {
    try {
      const posts: any[] = await strapi.getEntries('blogposts')
      const homePagePosts = posts.filter(post => post.page.name === props.page)
      setPosts(homePagePosts);
    }
    catch (err) { }
  }

  function getFullImageUrl(url: string) {
    return 'http://207.154.240.216:1338' + url;
  }

  return (
    <div className="row around-xs">
      <div className="col-xs-12 hero" style={{ backgroundImage: "url(" + getFullImageUrl('/uploads/aea9202a1cbc4962916929f574bc00f1.jpg') + ")" }}>
        <div className="hero-caption">
          Security & Control From Anywhere
        </div>
        <div className="hero-stripe">
          <span>
            613-738-7707
          </span> 
        </div>
      </div>
        {
          posts.length > 0 && posts.map((post, index) => {
            if (post.display === 'full') {
              return (
                <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 post full ${post.color}`}
                  key={index} style={{ backgroundImage: "url(" + getFullImageUrl(post.picture.url) + ")" }}>
                  <div className="box">
                    <h2 style={{
                      color: post.color || ''
                    }}>{post.title}</h2>
                    <p className="description" style={{
                      color: post.color || ''
                    }}>
                      {post.description}
                    </p>
                  </div>
                </div>
              )
            }
            else {
              return (
                <div className={`col-xs-12 col-sm-6 col-md-6 col-lg post half ${post.color}`} key={index} style={{ backgroundImage: "url(" + getFullImageUrl(post.picture.url) + ")" }}>
                  <div className="box">
                    <h2 style={{
                      color: post.color || ''
                    }}>{post.title}</h2>
                    <p className="description" style={{
                      color: post.color || ''
                    }}>
                      {post.description}
                    </p>
                  </div>
                </div>
              )
            }
          })
        }

      <ContactUsDivision></ContactUsDivision>

      <AboutDivision></AboutDivision>
    </div>
  );
}